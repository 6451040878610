// Importar las librerías requeridas
import React, { useState, useEffect } from 'react';
import './App.css';

// Componente Cabecera
const Cabecera = () => {
  const [esPequena, setEsPequena] = useState(false);

  const comprobarScroll = () => {
    // Cambia el estado si el usuario ha hecho scroll más de 50px
    if (window.scrollY > 50) {
      setEsPequena(true);
    } else {
      setEsPequena(false);
    }
  };

  useEffect(() => {
    // Añade el event listener cuando el componente se monta
    window.addEventListener('scroll', comprobarScroll);

    // Elimina el event listener cuando el componente se desmonta
    return () => {
      window.removeEventListener('scroll', comprobarScroll);
    };
  }, []); // El array vacío significa que este effect se ejecutará solo una vez, cuando el componente se monte

  return (
    <div className={`containerCabecera ${esPequena ? 'pequena' : ''}`}>
      <div className='topbar'>
        <ul className='topbar menu'>
          <div>
            <li>info@agrobridges.eu</li>
          </div>
          <div>
            <li>twitter</li>
            <li>linkedin</li>
          </div>
        </ul>
      </div>
      <div className="cabecera">
        <ul className="menu fijo">
          <li>The Project</li>
          <li>Consortium</li>
          <li>agroBRIDGES Toolbox</li>
          <li>MAP Forum</li>
          <li>News & Events</li>
          <li>Interactive Catalogue</li>
          <li>Catalogue of resources</li>
          <li>Contact</li>
        </ul>
      </div>
    </div>
  );
};

// Componente FondoConTitulo
const FondoConTitulo = () => {
  const [activeButton, setActiveButton] = useState(null);

  return (
    <div className="fondo">
      <h1 className="titulo-en-fondo">TRAINING SECTION</h1>
      <div className="botones">
        <button
          className={`boton get-involved ${activeButton === 'get-involved' ? 'active' : ''}`}
          onMouseEnter={() => setActiveButton('get-involved')}
          onMouseLeave={() => setActiveButton(null)}
        >
          Get Involved
        </button>
        <button
          className={`boton contact-us ${activeButton === 'contact-us' ? 'active' : ''}`}
          onMouseEnter={() => setActiveButton('contact-us')}
          onMouseLeave={() => setActiveButton(null)}
        >
          Contact Us
        </button>
      </div>
    </div>
  );
};


// Componente Overview
const Overview = () => {
  return (
    <div className="overview">
      <h1 className="titulo">Overview</h1>
      <div className="grid-container">
        <div className="grid-item foto">
          <img src="https://picsum.photos/500/450/?random" alt="Fotografía" />
        </div>
        <div className="grid-item texto">
          <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce at velit justo. Vestibulum a sapien id erat facilisis convallis a ac ligula. 
          Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Etiam fringilla fringilla justo, eget iaculis ex 
          faucibus sit amet. Nam ac massa nec elit varius vulputate. 
          Sed tempus erat vel libero elementum, sit amet scelerisque enim finibus. Duis tristique mi at scelerisque consectetur.
          </p>
        </div>
      </div>
    </div>
  );
};

// Componente Filter
const Filter = ({ onFilterChange }) => {
  const [selectedFilter, setSelectedFilter] = useState('');

  const handleFilterChange = (filter) => {
    setSelectedFilter(filter);
    onFilterChange(filter);
  };

  return (
    <div className="filter">
      <button
        className={selectedFilter === 'filtro1' ? 'active' : ''}
        onClick={() => handleFilterChange('filtro1')}
      >
ROADMAP
   </button>
      <button
        className={selectedFilter === 'filtro2' ? 'active' : ''}
        onClick={() => handleFilterChange('filtro2')}
      >
     WORKSHOPS & WEBINARS
  
      </button>
      <button
        className={selectedFilter === 'filtro3' ? 'active' : ''}
        onClick={() => handleFilterChange('filtro3')}
      >
      TRAIN-THE-TRAINER 
      </button>
    </div>
  );
};

const Articles = ({ filter }) => {

  const articles = [
    {
       id: 1,
       titulo: `Artículo 1`,
       text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce at velit justo. Vestibulum a sapien id erat facilisis convallis a ac ligula. ',
       imagen:`https://picsum.photos/500/450/?random`,        
       filtro: 'filtro1',
     },
    {
       id: 2,
       titulo: `Artículo 2`,
       text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce at velit justo. Vestibulum a sapien id erat facilisis convallis a ac ligula. ',
       imagen: `https://picsum.photos/500/450/?random`,
       filtro: 'filtro1',
 
     },
     {
       id: 3,
       titulo: `Artículo 3`,
       text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce at velit justo. Vestibulum a sapien id erat facilisis convallis a ac ligula. ',
       imagen: `https://picsum.photos/500/450/?random`,
       filtro: 'filtro1',
 
     },
     {
       id: 4,
       titulo: `Artículo 1`,
       text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce at velit justo. Vestibulum a sapien id erat facilisis convallis a ac ligula. ',
       imagen: `https://picsum.photos/500/450/?random`,
       filtro: 'filtro1',
 
     },
     {
       id: 5,
       titulo: `Artículo 2`,
       text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce at velit justo. Vestibulum a sapien id erat facilisis convallis a ac ligula. ',
       imagen: `https://picsum.photos/500/450/?random`,
       filtro: 'filtro2',
 
     },
     {
       id: 6,
       titulo: `Artículo 3`,
       text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce at velit justo. Vestibulum a sapien id erat facilisis convallis a ac ligula. ',
       imagen: `https://picsum.photos/500/450/?random`,
       filtro: 'filtro2',
 
     },
       {
       id: 7,
       titulo: `Artículo 1`,
       text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce at velit justo. Vestibulum a sapien id erat facilisis convallis a ac ligula. ',
       filtro: 'filtro2',
 
     },
    {
       id: 8,
       titulo: `Artículo 2`,
       text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce at velit justo. Vestibulum a sapien id erat facilisis convallis a ac ligula. ',
       imagen: `https://picsum.photos/500/450/?random`,
       filtro: 'filtro3',
 
     },
     {
       id: 9,
       titulo: `Artículo 3`,
       text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce at velit justo. Vestibulum a sapien id erat facilisis convallis a ac ligula. l',
       imagen: `https://picsum.photos/500/450/?random`,
       filtro: 'filtro3',
 
     },
   ]

  const articleFiltered = articles.filter((article) => article.filtro === filter);

  return (
    <div className="articles">
      {articleFiltered.map((article) => (
        <div key={article.id} className="article">
          <h3>{article.titulo}</h3>
          <img src={article.imagen}></img>
          <p>{article.text}</p>
        </div>
      ))}
    </div>
  );
};


// Componente Footer
const Footer = () => (
  <div className="footer">
    <p>Designed by FruitVegetablesEUROPE (EUCOFEL)</p>
    <p>Copyright 2021</p>
    <p>Cookie Policy| Privacy Polic| Contact Us</p>
    <p>
      This project has received funding from the European Union’s Horizon 2020
      Research and Innovation Programme under Grant Agreement N° 101000788
    </p>
  </div>
);

// Componente App
const App = () => {
  const [selectedFilter, setSelectedFilter] = useState('');

  const handleFilterChange = (filter) => {
    setSelectedFilter(filter);
  };

  return (
    <div className="app">
      <Cabecera />
      <FondoConTitulo />
      <Overview />
      <Filter onFilterChange={handleFilterChange} /> {/* Pasar la función onFilterChange como prop */}
      <Articles filter={selectedFilter} /> {/* Pasar el estado selectedFilter como prop */}
      <Footer />
    </div>
  );
};


export default App;
